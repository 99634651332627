<template>
  <section>
    <b-row class="mt-1">
      <b-col class="text-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :to="{ name: 'product-create' }"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Créer Produit</span>
        </b-button>
      </b-col>
    </b-row>
    <div class="grid-view wishlist-items mt-2">
      <b-card
        class="itemgrid-card"
        v-for="product in products"
        :key="product.ID"
        no-body
      >
        <b-link :to="{ name: 'product-detail', params: { id: product.ID } }">
          <b-img
            :alt="`${product.name}-${product.ID}`"
            fluid
            class="card-img-top"
            :src="`${publicPath}` + `images/products/` + `${product.logo}`"
          />
        </b-link>
        <!-- Product Details -->
        <b-card-body>
          <h4 class="item-name">
            <b-link
              class="text-body"
              :to="{ name: 'product-detail', params: { id: product.ID } }"
            >
              {{ product.name }}
            </b-link>
          </h4>
          <b-card-text class="item-description">
            {{ product.description }}
          </b-card-text>
        </b-card-body>
      </b-card>
    </div>
  </section>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BImg,
  BCardText,
  BLink,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import useProductsList from "./useProductsList";
import productStoreModule from "../productStoreModule";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BImg,
    BCardText,
    BLink,
    BButton,
  },
  mixins: [PageBoxed],
  directives: {
    Ripple,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  setup() {
    const products = ref([]);

    const PRODUCT_APP_STORE_MODULE_NAME = "app-product";

    const { fetchProducts } = useProductsList();

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME))
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, productStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME))
        store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME);
    });

    fetchProducts(products);

    return {
      products,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-itemgrid.scss";
</style>
