import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useProductsList() {
  // Use toast
  const toast = useToast();

  const fetchProducts = (products) => {
    store
      .dispatch("app-product/fetchProducts", {})
      .then((response) => {
        products.value = response.data;
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error fetching products list: ",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  return {
    fetchProducts,
  };
}
